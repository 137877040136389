body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import url('https://fonts.googleapis.com/css?family=La+Belle+Aurore');


.Typewriter, h1, h2 {
  font-size: 1.5em;
  color: #fff;
  line-height: 1.5em;
  font-family: 'Source Sans Pro', sans-serif;
}
.Typewriter{
  color: #fff;
  margin-top: 20px;
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 3px;
}

.top-tags {
  font-family: 'La Belle Aurore';
  user-select: none;
}

h1 {
  font-size: 32px;
}

.h2-designed {
  color: #bfbfbf;
  margin: 20px 0;
  font-weight: 400;
  font-size: 14px;
  font-family: "Open Sans",sans-serif;
  letter-spacing: 3px;
  -webkit-animation: myanim2 1s 1.8s backwards;
  animation: myanim2 1s 1.8s backwards;
}

.typingContainer:before {
  content: '<h1>';
  font-family: 'La Belle Aurore';
  -webkit-user-select: none;
  user-select: none;
  color: rgb(81, 81, 82);
  position: absolute;
  top: -11px;
  left: -15px;
}

.typingContainer:after {
  content: '</h1>';
  font-family: 'La Belle Aurore';
  -webkit-user-select: none;
  user-select: none;
  color: rgb(81, 81, 82);
  position: absolute;
  top: 205px;
  left: 544px;
}

textarea, select, input, button, a, [tabindex] { outline: none; }

.typingContainer{
  width: 576px;
  display: block;
  cursor: default;
  position: absolute;
  top: 31vh;
  left: 15vw;

}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 31px;
  height: 17px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #a3a8c1;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a3a8c160;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 0.8em;
  text-decoration: none;
  color: #b8b7ad;
  margin-left: 10px;
    font-weight: 700;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.blast {
  color: #fff;
  border-radius: 5px;  
  text-decoration: none;
  display: inline-block;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  font-size: 60px;
  font-weight: 600;
  line-height: 53px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: 'Source Sans Pro', sans-serif;
  user-select: none;

}

.rst__grid__title {
  color: black;
}

[tabindex] {
  user-select: none;
}

.blast:hover {
  animation-name: rubberBand;
  color: #08fdd8;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}


