@import url('https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap');

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
  font-size: 20px;
}

::selection {
  background: #2ddab8;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  background: #0c0f13;
  overflow: hidden;
  font-family: 'Sulphur Point', sans-serif;
  color: #8b8b8b;
  font-size: 0.9rem;
}

a {
  color: #545454;
  text-decoration: none;
}

a:focus, 
a:hover {
  color: #8b8b8b;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
  color: #8b8b8b;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.frame {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1.5rem;
  z-index: 2;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.frame__links {
  margin: 1rem 0;
}

.frame__link:not(:last-child) {
  margin-right: 1rem;
}

.frame__nav .frame__link:first-child {
  margin-right: 1.5rem;
}

.bottom-left {
  padding: 1.5rem;
  transform: translate3d(0, -100%, 0) !important;
}

.canvas > div {
  z-index: 10000000;
}

@media screen and (min-width: 53em) {
	body {
		overflow: hidden;
	}
	.frame {
    display: grid;
    height: 100vh;
    padding: 2rem 3.7rem;
    pointer-events: none;
		align-content: space-between;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links'
							'... ...'
							'... nav';
	}
	.frame__title {
    grid-area: title;
	}
	.frame__links {
		grid-area: links;
    justify-self: end;
    margin: 0;
  }
  .frame__link {
    pointer-events: auto;
  }
  .frame__link:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 50px;
    border: 2px solid #fff;
    border-radius: 50px;
    box-sizing: border-box;
    left: 50%;;
    bottom: -75px;
  }
  .frame__link:after {
    position: absolute;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: 12px;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sdb10 2s infinite;
    animation: sdb10 2s infinite;
    box-sizing: border-box;
    bottom: -39px;

  }
  @-webkit-keyframes sdb10 {
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb10 {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  .frame__nav {
    grid-area: nav;
		justify-self: end;
  }
  .bottom-left {
    padding: 0 0 2rem 3.7rem;
  }
}
